<script setup lang="ts">
import {useModalsStore} from "~/stores/modals";
import {ModalsContainer} from "vue-final-modal";

const route = useRoute()
const {t} = useI18n()

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true
})


const modals = useModalsStore();
</script>

<template>
  <div>
    <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
    <Head>
      <meta name="theme-color" content="#0b253c"/>
      <template v-for="link in head.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang"/>
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content"/>
      </template>
    </Head>
    <Body :class="{'overflow-hidden': modals.mainMobileMenu}">
    <div class="w-full flex flex-col">
      <div>
        <slot/>
        <ModalsContainer/>
      </div>
    </div>
    </Body>
    </Html>
  </div>
</template>

<style scoped>

</style>
